import React, { createRef, useMemo, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import useDebounce from "@utils/debounce";
import { DeepNonNullable } from "ts-essentials";
import useOnClickOutside from "@utils/hooks/useOnClickOutside";
import SearchBoxAlgolia from "@components/SearchBoxAlgolia";
import SearchResult from "@components/SearchResultsAlgolia";
import { AlgoliaCredentialsQuery } from "../../graphqlTypes";

const HelpSearch = () => {
  const data = useStaticQuery<DeepNonNullable<AlgoliaCredentialsQuery>>(graphql`
    query AlgoliaCredentials {
      strapiHelp {
        pageTitle
        title
        description
        searchTitle
        searchPlaceholder
      }
      site {
        siteMetadata {
          algoliaApiKey
          algoliaAppId
        }
      }
    }
  `);

  const { algoliaApiKey, algoliaAppId } = data.site.siteMetadata;
  const { searchTitle, searchPlaceholder } = data.strapiHelp;

  /** Search Related Section */
  const rootRef = createRef<HTMLElement>();
  const [query, setQuery] = useState<string>();
  /** To Avoid glitching effect on frequent updates of activeSection */
  const debouncedQuery = useDebounce<string | undefined>(query, 120);
  const [hasFocus, setFocus] = useState(false);
  const searchClient = useMemo(
    () => algoliasearch(algoliaAppId, algoliaApiKey),
    []
  );
  useOnClickOutside(rootRef, () => setFocus(false));
  /** End of Search Related Section */

  const indices = [{ name: `dev_help`, title: `dev_help` }];

  return (
    <div className="bg-accuRankerPurple-500 rounded-lg w-full z-10 px-10 py-8 -mt-4 mb-10 shadow-lg">
      <div
        ref={rootRef as React.RefObject<HTMLDivElement>}
        className="relative"
      >
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <span className="text-accuRankerPurpleOpacity-light">
            {searchTitle}
          </span>
          <SearchBoxAlgolia
            onFocus={() => setFocus(true)}
            placeholder={searchPlaceholder}
          />
          {hasFocus && debouncedQuery && debouncedQuery.length > 0 && (
            <SearchResult
              className="bg-white rounded-lg p-4 absolute w-full overflow-y-scroll z-50 shadow-md"
              indices={indices}
            />
          )}
        </InstantSearch>
      </div>
    </div>
  );
};

export default HelpSearch;
