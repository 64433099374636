import React from "react";
import { graphql } from "gatsby";
import { DeepNonNullable } from "ts-essentials";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import { HelpCategoryDataQuery } from "../../../graphqlTypes";
import Breadcrumb from "@components/Breadcrumb";
import HelpCard from "@ui/HelpCard";
import SVGLines from "@ui/SVGVerticalSearchLines";
import HelpSearch from "@components/HelpSearch";
import BannerSection from "@components/BannerSection";

export const query = graphql`
  query HelpCategoryData($slug: String!) {
    strapiHelpCategory(slug: { eq: $slug }) {
      name
      description
      slug
    }
    allStrapiHelpPage(
      filter: { category: { slug: { eq: $slug } } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        title
        subtitle
        slug
      }
    }
  }
`;

const HelpCategoryIndex = ({
  data,
}: {
  data: DeepNonNullable<HelpCategoryDataQuery>;
}) => {
  const { strapiHelpCategory, allStrapiHelpPage } = data;

  const { name, description, slug } = strapiHelpCategory;

  return (
    <Layout>
      <Seo title={name} />
      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto mt-4 mb-4">
        <Breadcrumb parents={[{ title: "Help", url: "/help/" }]} title={name} />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <p className="text-4xl text-white font-semibold mt-16">{name}</p>
            <p className="text-white mb-16">{description}</p>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="div-block max-w-fit md:max-w-7xl bg-accuRankerCream w-full pb-12 mx-5 md:m-auto px-0 sm:px-0">
          <div className="max-w-7xl flex flex-col gap-11 m-auto sm:px-0">
            <div>
              <div className="relative z-10">
                <SVGLines />
                <HelpSearch />
                <div className="w-full flex flex-col gap-8">
                  {allStrapiHelpPage.nodes.map(helpPage => {
                    return <HelpCard {...helpPage} categorySlug={slug} />;
                  })}
                </div>
              </div>
              {/*<BannerSection />*/}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Footer />
    </Layout>
  );
};

export default HelpCategoryIndex;
