import React from "react";

export const SVGLines = () => (
  <div
    className="absolute h-full"
    style={{
      top: "-40px",
      left: "20px",
      zIndex: -1,
    }}
  >
    <svg width="48" height="6">
      <circle
        cx="45"
        cy="3"
        r="2"
        fill="white"
        stroke="#C7DB4E"
        stroke-width="2"
      />
      <circle
        cx="31"
        cy="3"
        r="2"
        fill="white"
        stroke="#82CA69"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
      <circle
        cx="17"
        cy="3"
        r="2"
        fill="white"
        stroke="#38E2A5"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
      <circle
        cx="3"
        cy="3"
        r="2"
        fill="white"
        stroke="#38B9E2"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
    </svg>
    <svg
      width="48"
      height="100%"
      viewBox="0 0 48 36"
      fill="none"
      preserveAspectRatio="none"
      style={{
        marginTop: "-2px",
      }}
    >
      <rect
        x="47"
        y="36"
        height="100%"
        width="4.00001"
        transform="rotate(-180 47 36)"
        fill="#C7DB4E"
      />
      <rect
        x="33"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 33 36)"
        fill="#82CA69"
      />
      <rect
        x="19"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 19 36)"
        fill="#38E2A5"
      />
      <rect
        x="5"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 5 36)"
        fill="#38B9E2"
      />
    </svg>
  </div>
);

export default SVGLines;
