import React from "react";
import { Link } from "gatsby";

const HelpCard = ({
  categorySlug,
  slug,
  title,
  subtitle,
}: {
  categorySlug: string;
  slug?: string;
  title: string;
  subtitle: string;
}) => {
  if (slug && !slug.endsWith("/")) {
    slug += "/";
  }

  return (
    <Link
      className="flex flex-row rounded-lg cursor-pointer bg-gray-100 border-2 shadow-lg"
      to={`/help/${categorySlug}/${slug || ""}`}
    >
      <div
        className="flex flex-col p-6 rounded-lg gap-2"
        style={{
          zIndex: 10,
        }}
      >
        <h2>{title}</h2>
        <span className="text-gray-500">{subtitle}...</span>
      </div>
    </Link>
  );
};

export default HelpCard;
